import { graphql } from 'gatsby'
import React from 'react'
import Header from '../../components/shared/Header'
import FooterSection from '../../components/PageComponents/FooterSection'
import ThemeChanger from '../../components/shared/ThemeChanger'
import Layout from '../../components/StructureComponents/Layout'
import SingleWhatsNewReleaseBody from '../../components/PageComponents/SingleWhatsNewReleaseBody/index'
import { Helmet } from 'react-helmet'

/**
 *
 * @param {whats_new_releases} object
 */

const SingleWhatsNewRelease = (props) => {
  // BreadCrums

  return (
    <>
      <Layout
        meta={
          props?.data?.current_release?.frontmatter?.meta ||
          (typeof window !== 'undefined' && window?.location?.pathname) ||
          ''
        }
      >
        <Helmet>
          <meta name={`robots`} content={`noindex, nofollow`} />
          <meta name={`googlebot`} content={`noindex, nofollow`} />
        </Helmet>
        <div className="singleWhatsNewRelease">
          <Header />
          <ThemeChanger />

          <SingleWhatsNewReleaseBody {...props?.data?.current_release} />

          <FooterSection
            {...props?.data?.indexPage?.frontmatter?.footersection}
          />
        </div>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  ## Query for SingleWhateNewRelease data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SingleWhatsNewRelease($id: String!) {
    indexPage: markdownRemark(frontmatter: { template: { eq: "HomePage" } }) {
      frontmatter {
        footersection {
          primaryHeading
          description
          secondaryheading
          tertiaryheading
          footerList {
            primaryheading
            footerLink
          }
        }
      }
    }

    current_release: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        meta {
          canonicalLink
        }

        title
        slug
        date(formatString: "MMM D YYYY")

        featuredimage {
          image
          alt
        }
      }
    }
    #END OF Query
  }
`

export default SingleWhatsNewRelease
